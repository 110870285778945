import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { days: String, human: String };

  connect() {
    this.humanUsed = true;

    if (this._isVlid()) {
      this.element.classList.add('link', 'cursor-pointer', 'select-none');
    }
  }

  toggle() {
    if (!this._isVlid()) return;

    this.element.textContent = this.humanUsed ? this.daysValue : this.humanValue;
    this.humanUsed = !this.humanUsed;
  }

  _isVlid() {
    return this.daysValue + ' days' !== this.humanValue && this.daysValue !== '-';
  }
}
