import Rails from '@rails/ujs';
import { Controller } from '@hotwired/stimulus';

const NOTIFICATIONS_UPDATE_PATH = '/notifications/:id';

export default class extends Controller {
  dismiss() {
    const formData = new FormData();

    formData.append('notification[status]', 'dismissed');

    Rails.ajax({
      type: 'PATCH',
      url: this._updatePath(),
      data: formData,
      success: () => this.element.remove()
    });
  }

  _updatePath() {
    return NOTIFICATIONS_UPDATE_PATH.replace(':id', this.element.dataset.id);
  }
}
