import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'clear'];

  connect() {
    this.inputTarget.addEventListener('change', this._textChangeHandler);
  }

  clear() {
    this.inputTarget.value = '';
    this.clearTarget.classList.add('hidden');
    this.inputTarget.focus();
  }

  changed() {
    this._toggleClearButton();
  }

  _toggleClearButton() {
    this.clearTarget.classList.toggle('hidden', this.inputTarget.value === '')
  }
}
