import { Controller } from '@hotwired/stimulus';
import Choices from 'choices.js';

const OPTIONS = {
  removeItemButton: true,
  itemSelectText: '',
  noChoicesText: 'No options available',
  placeholderValue: 'Select',
};

export default class extends Controller {
  static targets = ['select'];
  static outlets = ['choices'];

  connect() {
    this.choices = new Choices(this.selectTarget, OPTIONS);

    if (this.hasChoicesOutlet) {
      this._cacheOptions();
    }
  }

  disconnect() {
    this.choices?.destroy();
  }

  choicesOutletConnected(outlet, element) {
    outlet.selectTarget.addEventListener('change', this._handleChoicesOutletChange.bind(this))
  }

  _handleChoicesOutletChange({ target: { value } }) {
    const options = (
      value
        ? this.options.filter((option) => option.value.startsWith(`${value}-`))
        : this.options
    ).sort((a, b) => a < b ? -1 : 1);

    this.choices.clearStore();
    this.choices.setChoices(options, 'value', 'label', true);
  }

  _cacheOptions() {
    this.options = Array.from(this.selectTarget.options).map(option => ({
      value: option.value,
      label: option.innerText
    }));
  }
}
