import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['menu'];

  connect() {
    this.open = false;
  }

  toggle() {
    this.open = !this.open;
    this.menuTarget.classList.toggle('open', this.open);
  }
}
