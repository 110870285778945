import { Controller } from '@hotwired/stimulus';

const AUTO_DISMISS_MS = 10_000;

export default class extends Controller {
  static values = { timeout: Number }

  connect() {
    this.timeout = setTimeout(this.dismiss.bind(this), AUTO_DISMISS_MS);
  }

  disconnect() {
    clearTimeout(this.timeout);
  }

  dismiss() {
    this.element.classList.add('removing');
    setTimeout(() => this.element.remove(), 300);
  }
}
