import Rails from '@rails/ujs';
import { Controller } from '@hotwired/stimulus';

const POLLING_PATH = '/delayed_jobs/polling';
const POLLING_MILLISECONDS = 3000;

export default class extends Controller {
  static targets = ['queues', 'enqueued', 'working', 'pending', 'failed'];

  connect() {
    this.polling = false;
  }

  togglePolling({ currentTarget }) {
    this.polling = !this.polling;
    this._togglePollingButonState(currentTarget);

    if (!this.polling) {
      return clearInterval(this.interval);
    }

    this._pollJobCounts();
    this.interval = setInterval(this._pollJobCounts.bind(this), POLLING_MILLISECONDS);
  }

  _pollJobCounts() {
    Rails.ajax({
      type: 'GET',
      url: POLLING_PATH,
      success: this._updateJobCounts.bind(this)
    });
  }

  _togglePollingButonState(pollingButton) {
    if (this.polling) {
      pollingButton.classList.replace('primary', 'danger');
      pollingButton.textContent = 'Stop Poll';
    } else {
      pollingButton.classList.replace('danger', 'primary');
      pollingButton.textContent = 'Live Poll';
    }
  }

  _updateJobCounts({
    queue_counts: queueCounts,
    working_count: workingCount,
    pending_count: pendingCount,
    failed_count: failedCount
  }) {
    this.queuesTarget.innerHTML = this._queuesHTML(queueCounts);
    this.enqueuedTarget.textContent = this._totalEnqueued(queueCounts);
    this.workingTarget.textContent = workingCount;
    this.pendingTarget.textContent = pendingCount;
    this.failedTarget.textContent = failedCount;
  }

  _totalEnqueued(queueCounts) {
    return Object.values(queueCounts).reduce((acc = 0, count) => acc + count);
  }

  _queuesHTML(queueCounts) {
    return Object.entries(queueCounts).map(this._queueHTML).join('');
  }

  _queueHTML([queue, count]) {
    return `<tr><td>${queue}</td><td class="text-right">${count}</td></tr>`;
  }
}
