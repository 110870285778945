import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dropdown', 'toggle'];

  connect() {
    this.isOpen = false
  }

  toggle() {
    if (this.isOpen) {
      this._close();
    } else {
      this._open();
    }
  }

  _open() {
    this.dropdownTarget.classList.remove('hidden');
    this.toggleTarget.classList.add('open');
    this._registerCloseListeners();
    this.isOpen = true;
  }

  _close() {
    this._deregisterCloseListeners();
    this.dropdownTarget.classList.add('hidden');
    this.toggleTarget.classList.remove('open');
    this.isOpen = false;
  }

  _registerCloseListeners() {
    document.addEventListener('keydown', this._closeOnEscapeKeyPress);
    document.addEventListener('click', this._closeOnOutsideClick);
  }

  _deregisterCloseListeners() {
    document.removeEventListener('keydown', this._closeOnEscapeKeyPress);
    document.removeEventListener('click', this._closeOnOutsideClick);
  }

  _closeOnEscapeKeyPress = ({ key, keyCode }) => {
    if (this._isEscapeKey(key || keyCode)) {
      this._close();
    }
  }

  _isEscapeKey(value) {
    return value === 'Escape' || value === 'Esc' || value === 27;
  }

  _closeOnOutsideClick = ({ target }) => {
    if (this.element.contains(target)) {
      return;
    }

    this._close();
  }
}
