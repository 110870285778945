import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal'];

  open() {
    this.modalTarget.classList.remove('hidden');
    document.addEventListener('keydown', this._closeOnEscapeKeyPress);
  }

  close() {
    document.removeEventListener('keydown', this._closeOnEscapeKeyPress);
    this.modalTarget.classList.add('hidden');
  }

  _closeOnEscapeKeyPress = ({ key, keyCode }) => {
    if (this._isEscapeKey(key || keyCode)) {
      this.close();
    }
  }

  _isEscapeKey(value) {
    return value === 'Escape' || value === 'Esc' || value === 27;
  }
}
