import { Controller } from '@hotwired/stimulus';
import JSONFormatter from 'json-formatter-js';
import moment from 'moment';

export default class extends Controller {
  static targets = ['json', 'time'];

  connect() {
    this.jsonTargets.forEach(this._formatJSON);
    this.timeTargets.forEach(this._formatTime.bind(this));
  }

  toggleTime({ currentTarget }) {
    const title = currentTarget.title;

    currentTarget.title = currentTarget.textContent;
    currentTarget.textContent = title;
  }

  _formatTime(timeElement) {
    const timestamp = moment(timeElement.textContent);

    timeElement.innerHTML = this._timeButtonHTML(timestamp);
  }

  _timeButtonHTML(timestamp) {
    const button = document.createElement('BUTTON');

    button.type = 'button';
    button.title = timestamp.format('H:mm:ss DD/MM/YYYY');
    button.dataset.action = 'table#toggleTime';
    button.textContent = timestamp.fromNow();

    return button.outerHTML;
  }

  _formatJSON(jsonElement) {
    const expanded = jsonElement.dataset.expanded === 'true';
    const expandDepth = jsonElement.dataset.depth || 1;
    const formatter = new JSONFormatter(JSON.parse(jsonElement.textContent), expanded);

    if (expanded) {
      formatter.openAtDepth(expandDepth);
    }

    jsonElement.innerHTML = '';
    jsonElement.appendChild(formatter.render());
  }
}
